<template>
  <div
    v-show="show"
    class="v-player--error-display"
  >
    <div class="v-player--error-display--container">
      <div
        v-html="error ? error.message : ''"
        class="v-player--error-display--title"
      >
      </div>

      <div class="v-player--error-display--actions">
        <v-button
          class="v-player--button--back"
          color="secondary"
          hoverColor="primary"
          label=""
          @click="$router.back()"
        >
          {{ $t('player.buttons.back') }}
        </v-button>

        <v-button
          class="v-player--button--reload ml-3"
          color="secondary"
          hoverColor="primary"
          @click="handleReload"
        >
          {{ $t('player.buttons.reload') }}
        </v-button>
      </div>
    </div>
  </div>
</template>

<script>
import VButton from '@/components/VButton'

export default {
  name: 'ErrorDisplay',

  data () {
    return {
      show: false,
      error: null
    }
  },

  components: {
    VButton
  },

  mounted () {
    this.$parent.$on('player:canplay', this.handleVideoCanPlay)
    this.$parent.$on('player:error', this.handleDisplay)
  },

  beforeDestroy () {
    this.$parent.$off('player:canplay', this.handleVideoCanPlay)
    this.$parent.$off('player:error', this.handleDisplay)
  },

  methods: {
    handleVideoCanPlay () {
      this.show = false
      this.error = null
    },

    handleDisplay (e) {
      const error = e.detail || e

      var code = null
      var message = null

      if (error instanceof shaka.util.Error) {
        code = error.code
        message = this.$t('player.errors.playback', { code })
      } else {
        code = 0
        message = this.$t('player.errors.unsupported')
      }

      this.error = { code, message }
      this.show = true
    },

    handleReload () {
      this.show = false
      this.$parent.reload()
    }
  }
}
</script>
