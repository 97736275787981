import axios from 'axios'
import axiosRetry from 'axios-retry'

import { getLocale } from '@/utils/helpers'

const api = axios.create({
  baseURL: process.env.VUE_APP_MONOMAX_API_ENDPOINT_URL,
  timeout: 30000
})

axiosRetry(api, {
  retryDelay: (retryCount) => retryCount * 1000
})

setLocale(getLocale())

function fetch (path, params) {
  return api.get(path, { params })
    .then(res => res.data)
}

function send (path, data) {
  return api.post(path, data)
    .then(res => res.data)
}

export function setToken (token) {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

export function removeToken () {
  delete api.defaults.headers.common.Authorization
}

export function setLocale (lang) {
  api.defaults.headers.common['Accept-Language'] = lang || 'en'
}

export function setProfile (id) {
  api.defaults.headers.common['X-Monomax-Subprofile'] = id
}

export function removeProfile () {
  delete api.defaults.headers.common['X-Monomax-Subprofile']
}

export function fetchHome (type) {
  return fetch(type !== 'home' ? `/${type}/home` : '/home')
}

export function fetchHomeGenre (type, genre) {
  return fetch(`/${type}/genre/${genre}/home`)
}

export function fetchHomeOrigin (type, origin) {
  return fetch(`/${type}/origin/${origin}/home`)
}

export function fetchHomeUpcoming (page, limit) {
  return fetch('/upcoming', { page, limit })
}

export function fetchHomeShortClips (page, limit) {
  return fetch('/shortclips', { page, limit })
}

export function fetchLiveTVPrograms (id) {
  return fetch(`/livetv/channels/${id}/programs`)
}

export function fetchTypeNav (type) {
  return fetch(`/${type}/nav`)
}

export function fetchGenreItems (type, origin, genre, page, limit) {
  return fetch(`/${type}/origin/${origin}/genre/${genre}`, { page, limit })
}

export function fetchCategoryItems (slug, page, limit) {
  return fetch(`/categories/${slug}`, { page, limit })
}

export function fetchTagItems (slug, page, limit) {
  return fetch(`/tags/${slug}`, { page, limit })
}

export function fetchPeopleItems (slug, page, limit) {
  return fetch(`/people/${slug}`, { page, limit })
}

export function fetchSearch (q, page, limit) {
  return fetch('/search/keyword', { q, page, limit })
}

export function fetchSearchFilters (kind, genre, sort, page, limit) {
  return fetch('/search/product', { kind, genre, sort, page, limit })
}

export function fetchSearchOptions () {
  return fetch('/search/options')
}

export function fetchPopularSearches () {
  return fetch('/search/title/popular')
}

export function fetchHistory (page, limit) {
  return fetch('/history', { page, limit })
}

export function fetchFavorite (page, limit) {
  return fetch('/fav', { page, limit })
}

export function addFavorite (id) {
  return send(`/products/${id}/add_fav`)
}

export function deleteFavorite (id) {
  return send(`/products/${id}/delete_fav`)
}

export function addProductReminder (id) {
  return send(`/products/${id}/remind`)
}

export function removeProductReminder (id) {
  return send(`/products/${id}/delete_remind`)
}

export function fetchProduct (id) {
  return fetch(`/products/${id}`, { availability: 'all' })
}

export function fetchProductTrailer (id) {
  return fetch(`/products/${id}/trailers`)
}

export function fetchStream (id, secure) {
  var params = {}

  if (secure) params.features = 'use_drm'

  return fetch(`/products/${id}/streams`, params)
}

export function fetchRelated (id) {
  return fetch(`/products/${id}/related2`)
}

export function fetchAvatars () {
  return fetch('/subprofile/avatars')
}

export function fetchUser () {
  return fetch('/account/profile')
}

export function fetchDeviceToken () {
  return send('/account/devicetoken')
}

export function fetchDeviceLogin (token) {
  return send('/account/devicelogin', { token })
}

export function fetchQrcodePromptpay (productId, episodeId, supported) {
  var url = `/products/${productId}/episodes/${episodeId}/buy_promptpay`

  if (typeof supported === 'boolean' && supported === false) {
    url = `${url}?support_svg=false`
  }

  return send(url)
}

export function forgotPasswordEmail (email) {
  return send('/account/forget_password_email', { email })
}

export function forgotPasswordSMS (telno) {
  return send('/account/forget_password_telno', { telno })
}

export function verifyOTPCode (ref, code) {
  return send('/account/verify_forget_password_telno', { ref, code })
}

export function resendOTPCode (ref) {
  return send('/account/resend_forget_password_telno', { ref })
}

export function changePassword (password, password2) {
  return send('/account/new_password', { password, password2 })
}

export function checkPassword (username, password) {
  return send('/account/lg_check_password', { username, password })
}

export function fetchDevices () {
  return fetch('/devices')
}

export function removeDevice (id) {
  return send(`/devices/${id}/remove`)
}

export function checkConsentUpdate () {
  return fetch('/account/check_consent')
}

export function acceptConsent (version) {
  return send('/account/accept_consent', { version })
}

export function sendWatch (id, episodeId, pos, len) {
  return send(`/products/${id}/${episodeId}/watch`, { pos, len })
}

export function sendEndWatchSession () {
  return send('/player/closed')
}

export function login (username, password) {
  return send('/account/login', { username, password })
}

export function logout () {
  return send('/account/logout')
}

export function refreshToken (token) {
  return send('/refresh_token', { refresh_token: token })
}

export function createProfile (id, name) {
  return send('/subprofile/add', { id, name })
}

export function updateProfile (id, name, template_id) {
  return send('/subprofile/update', { id, name, template_id })
}

export function deleteProfile (id) {
  return send('/subprofile/delete', { id })
}

export function fetchMessages (next) {
  return fetch('/inbox/recent', { next })
}

export function checkMessageUnread () {
  return fetch('/inbox/unreadcount')
}

export function sendMessageMarkAsRead () {
  return send('/inbox/markasread')
}

export default api
