<template>
  <div
    :class="{
      'v-avatar': true,
      'v-avatar--rounded': rounded
    }"
  >
    <v-image
      class="v-avatar--image"
      :style="{ ...sizeClasses }"
      :src="image"
      alt="avatar"
    />

    <div
      v-if="caption"
      class="v-avatar--caption"
    >
      {{ caption }}
    </div>

    <slot />
  </div>
</template>

<script>
import VImage from '../VImage'

export default {
  name: 'VAvatar',

  props: {
    image: String,
    caption: String,
    size: {
      type: [Number, String],
      default: 48
    },
    rounded: Boolean
  },

  computed: {
    sizeClasses () {
      var classes = {}

      if (typeof this.size === 'number') {
        classes.width = `${this.size}px`
      } else if (typeof this.size === 'string' && this.size !== 'auto') {
        classes.width = this.size
      }

      return classes
    }
  },

  components: {
    VImage
  }
}
</script>

<style lang="scss">
@import './VAvatar.scss';
</style>
