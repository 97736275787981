import { render, staticRenderFns } from "./ErrorDisplay.vue?vue&type=template&id=68cd2146"
import script from "./ErrorDisplay.vue?vue&type=script&lang=js"
export * from "./ErrorDisplay.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports