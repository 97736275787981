<template>
  <v-carousel
    v-if="items.length"
    v-sn="snOptions"
    class="nav-list"
    :perPage="perPage"
    gutter="8"
    :breakpoints="{
      1920: { gutter: 12 }
    }"
    centered
    @carousel:moved="handleListMoved"
    @carousel:ready="handleListReady"
    @mouseenter.native="handleListArrowToggle(true)"
    @mouseleave.native="handleListArrowToggle(false)"
    ref="navList"
  >
    <v-carousel-slide
      v-for="(item, itemIndex) in items"
      :key="item.id"
    >
      <router-link
        v-sn-focusable
        class="v-carousel--slide--link"
        :to="getRoutePath(item)"
        data-sn-autofocus="disable"
        @sn:willmove.native="handleListItemBeforeDeactive"
        @sn:active.native="handleListItemActive($event, itemIndex)"
        @mouseover.native="handleListItemAutoFocus"
      >
        {{ item.name }}
      </router-link>
    </v-carousel-slide>

    <v-navigation
      v-model="showNavigation"
      direction="horizontal"
      ref="navigation"
      slot="arrow"
    />
  </v-carousel>
</template>

<script>
import {
  VCarousel,
  VCarouselSlide
} from '@/components/VCarousel'

import VNavigation from '@/components/VNavigation'

import { mapState } from 'vuex'

export default {
  name: 'NavList',

  props: {
    items: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      snOptions: {
        section: 'navs-list',
        options: {
          straightOnly: true,
          leaveFor: {
            left: '@navbar',
            down: '@products-list'
          }
        }
      },
      isListMove: false,
      perPage: 6,
      showNavigation: false,
      currentListItem: 0,
    }
  },

  computed: {
    ...mapState(['activePlatformCursor']),
  },

  watch: {
    activePlatformCursor (active) {
      if (!active) this.showNavigation = false
    }
  },

  components: {
    VCarousel,
    VCarouselSlide,
    VNavigation
  },

  methods: {
    getRoutePath (nav) {
      const { page, value, params } = nav.more

      if (page === 'tag') {
        return { name: 'tag', params: { slug: value } }
      } else if (page === 'category') {
        return { name: 'category', params: { slug: value } }
      } else if (page === 'origin-home') {
        return { name: 'origin', params: { origin: params.origin, type: params.type } }
      } else if (page === 'genre-home') {
        return { name: 'genre',  params: { genre: params.genre, type: params.type } }
      } else if (page === 'origin-genre') {
        return { name: 'origin-genre', params: { origin: params.origin, genre: params.genre, type: params.type } }
      } else {
        return { name: 'home' }
      }
    },

    _canListItemMove (direction) {
      if (['left', 'right'].indexOf(direction) === -1) {
        return false
      }

      const { slides, finalIndex, range, perPage, index, offset } = this.$refs.navList
      const edgeStart = offset - 1
      const edgeEnd = finalIndex - edgeStart

      if (
        (slides.length <= perPage) ||
        (range.start === 0 && ((direction === 'left' && index <= edgeStart) || (direction === 'left' && this.currentListItem <= edgeStart) || (direction === 'right' && this.currentListItem < edgeStart))) ||
        (range.end === finalIndex && ((direction === 'right' && index >= edgeEnd) || (direction === 'left' && this.currentListItem > edgeEnd) || (direction === 'right' && this.currentListItem >= edgeEnd)))
      ) {
        return false
      } else {
        return true
      }
    },

    handleListItemBeforeDeactive (e) {
      if (this.isListMove) {
        e.preventDefault()
        return
      }

      const { direction } = e.detail

      if (this._canListItemMove(direction)) {
        const navList = this.$refs.navList

        this.isListMove = true

        if (direction === 'left') {
          navList.go(this.currentListItem - 1)
        } else if (direction === 'right') {
          navList.go(this.currentListItem + 1)
        }
      }
    },

    handleListItemActive (e, itemIndex) {
      this.currentListItem = itemIndex
    },

    handleListItemAutoFocus (e) {
      if (!this.isListMove) this.handleSnAutoFocus(e)
    },

    handleListMoved () {
      this.updateListArrowDisplay()
      this.isListMove = false
    },

    handleListReady () {
      this.updateListArrowDisplay()
    },

    handleListArrowToggle (show) {
      if (this.items.length <= this.perPage) {
        return
      }

      this.$sn.set(this.snOptions.section, {
        restrict: show ? 'self-only' : 'none'
      })

      this.showNavigation = show
    },

    updateListArrowDisplay () {
      const navigation = this.$refs.navigation
      const { index, finalIndex, offset } = this.$refs.navList
      const edgeStart = offset - 1
      const edgeEnd = finalIndex - edgeStart

      navigation.$refs.left[0].$el.disabled = index <= edgeStart
      navigation.$refs.right[0].$el.disabled = index >= edgeEnd
    }
  }
}
</script>
