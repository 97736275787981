<template>
  <v-button
    v-show="show"
    class="v-player--button--skip"
    color="secondary"
    hoverColor="primary"
    @click="handleClick"
  >
    {{ button ? button.label : '' }}
  </v-button>
</template>

<script>
import VButton from '@/components/VButton'

import _find from 'lodash/find'

import {
  PLAYER_SKIP_DELAY_SECONDS,
  PLAYER_SKIP_INTRO,
  PLAYER_SKIP_RECAP,
  PLAYER_SKIP_CREDIT
} from '@/utils/constants'

export default {
  name: 'SkipButton',

  data () {
    return {
      button: null
    }
  },

  props: {
    show: Boolean
  },

  model: {
    prop: 'show',
    event: 'button:toggle'
  },

  components: {
    VButton
  },

  mounted () {
    this.$parent.$on([
      'player:timeupdate',
      'player:ended',
      'player:seeking',
    ], this.handleDisplay)
  },

  beforeDestroy () {
    this.$parent.$off([
      'player:timeupdate',
      'player:ended',
      'player:seeking',
    ], this.handleDisplay)
  },

  methods: {
    handleDisplay () {
      const player_ = this.$parent
      const { index, playlists } = player_.playback
      const track = playlists[index]
      const isLastTrack = index === playlists.length - 1

      if (player_.isLive() || !track.skip || !player_.hasVideoPlayed) {
        return
      }

      const now = player_.currentTime()

      const intro = _find(track.skip, { type: PLAYER_SKIP_INTRO })
      const introTimeStart = intro ? intro.begin : 0
      const introTimeEnd = intro ? intro.begin + PLAYER_SKIP_DELAY_SECONDS : 0

      const recap = _find(track.skip, { type: PLAYER_SKIP_RECAP })
      const recapTimeStart = recap ? recap.begin : 0
      const recapTimeEnd = recap ? recap.begin + PLAYER_SKIP_DELAY_SECONDS : 0

      const credit = _find(track.skip, { type: PLAYER_SKIP_CREDIT })
      const creditTimeStart = credit ? credit.begin : 0
      const creditTimeEnd = credit ? credit.begin + PLAYER_SKIP_DELAY_SECONDS : 0

      if (!player_.hasSkipIntroDisplayed && !player_.isVideoPaused && introTimeStart !== introTimeEnd && now >= introTimeStart && now <= introTimeEnd) {
        this.button = { type: intro.type, label: intro.text }
      } else if (!player_.hasSkipRecapDisplayed && !player_.isVideoPaused && recapTimeStart !== recapTimeEnd && now >= recapTimeStart && now <= recapTimeEnd) {
        this.button = { type: recap.type, label: recap.text }
      } else if (!isLastTrack && !player_.hasSkipCreditDisplayed && !player_.isVideoPaused && creditTimeStart !== creditTimeEnd && now >= creditTimeStart && now <= creditTimeEnd) {
        this.button = { type: credit.type, label: credit.text }
      } else if (this.button !== null) {
        const buttonType = this.button.type

        if (buttonType === PLAYER_SKIP_INTRO) {
          player_.hasSkipIntroDisplayed = true
        } else if (buttonType === PLAYER_SKIP_RECAP) {
          player_.hasSkipRecapDisplayed = true
        } else if (buttonType === PLAYER_SKIP_CREDIT) {
          player_.hasSkipCreditDisplayed = true
        }

        this.button = null
      }

      const show = !!this.button

      if (show === this.show) {
        return
      }

      if (!show) {
        player_.$el.focus()
      } else if (!player_.isUserActive) {
        this.$el.focus()
      }

      this.$emit('button:toggle', show)
    },

    handleClick (e) {
      if (!this.button) return

      const player_ = this.$parent

      if (this.button.type === PLAYER_SKIP_INTRO) {
        player_.hasSkipIntroDisplayed = true
        this.handleIntroClick()
      } else if (this.button.type === PLAYER_SKIP_RECAP) {
        player_.hasSkipRecapDisplayed = true
        this.handleRecapClick()
      } else if (this.button.type === PLAYER_SKIP_CREDIT) {
        player_.hasSkipCreditDisplayed = true
        this.handleCreditClick()
      }

      player_.$el.focus()

      this.$emit('button:toggle', false)
      this.$emit('click', e)
    },

    handleIntroClick () {
      const player_ = this.$parent
      const { index, playlists } = player_.playback
      const track = playlists[index]
      const intro = _find(track.skip, { type: PLAYER_SKIP_INTRO })

      if (typeof intro === 'undefined' || intro && intro.end === 0) {
        return
      }

      player_.play(intro.end)
    },

    handleRecapClick () {
      const player_ = this.$parent
      const { index, playlists } = player_.playback
      const track = playlists[index]
      const recap = _find(track.skip, { type: PLAYER_SKIP_RECAP })

      if (typeof recap === 'undefined' || recap && recap.end === 0) {
        return
      }

      player_.play(recap.end)
    },

    handleCreditClick () {
      const player_ = this.$parent
      const { index, playlists } = player_.playback
      const track = playlists[index]
      const credit = _find(track.skip, { type: PLAYER_SKIP_CREDIT })

      if (typeof credit === 'undefined') {
        return
      }

      if (typeof credit.end === 'number' && credit.end > 0) {
        player_.play(credit.end)
      } else {
        player_.nextEpisode()
      }
    }
  }
}
</script>
