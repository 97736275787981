export default {
  beforeRouteLeave (to, from, next) {
    this.$store.commit("SET_ACTIVE_PLATFORM_BACK", false)
    next()
  },

  mounted () {
    this.$nextTick(() => this.$store.commit("SET_ACTIVE_PLATFORM_BACK", true))
  }
}
