<template>
  <div
    :class="{
      'v-image': true,
      'v-image--rounded': rounded
    }"
  >
    <div
      class="v-image--responsive"
      :style="{
        'padding-bottom': `${this.aspectRatio * 100}%`
      }"
    >
    </div>

    <slot>
      <img
        v-show="show"
        class="v-image--image"
        :alt="alt"
        ref="image"
      />
    </slot>
  </div>
</template>

<script>
export default {
  name: 'v-image',

  props: {
    src: String,
    lazySrc: String,
    alt: String,
    aspectRatio: {
      type: Number,
      default: 1
    },
    rounded: Boolean
  },

  data () {
    return {
      show: false
    }
  },

  watch: {
    src () {
      this._loadImage()
    },

    lazySrc () {
      this._loadImage()
    }
  },

  mounted () {
    this._loadImage()
  },

  methods: {
    _loadImage () {
      if (this.lazySrc && 'IntersectionObserver' in window) {
        this.imageObserver = new IntersectionObserver(this.handleIntersectObserver, {
          root: undefined,
          rootMargin: '0px',
          threshold: 0,
        })

        this.imageObserver.observe(this.$el)
      } else {
        this.loadImage(this.src || this.lazySrc)
      }
    },

    handleIntersectObserver (entries, observe) {
      entries.forEach(entry => {
        if (!entry.isIntersecting) return

        if (this.lazySrc) {
          this.loadImage(this.lazySrc)
        }

        observe.unobserve(this.$el)
      })
    },

    onLoad () {
      this.show = true
      this.$emit('image:loaded', this.src || this.lazySrc)
    },

    onError () {
      this.$emit('image:error', this.src || this.lazySrc)
    },

    loadImage (src) {
      if (["undefined", null, ''].indexOf(typeof src) !== -1) {
        return
      }

      const image = this.$refs.image

      image.onload = () => this.onLoad()
      image.onerror = () => this.onError()

      this.$refs.image.src = src
    }
  }
}
</script>

<style lang="scss">
@import './VImage';
</style>
