<template>
  <router-link
    v-slot="{ navigate }"
    :to="{
      name: 'people',
      params: { slug: this.people.slug }
    }"
    custom
  >
    <div
      v-sn-focusable
      class="v-carousel--slide--link peopleitem"
      data-sn-autofocus="disable"
      @click="navigate"
      @keypress.enter="navigate"
      role="link"
    >
      <v-avatar
        :image="people.image_url"
        :caption="people.title"
        size="auto"
        rounded
      />
    </div>
  </router-link>
</template>

<script>
import VAvatar from '@/components/VAvatar'

export default {
  name: 'PeopleItem',

  props: {
    index: Number,
    people: Object
  },

  components: {
    VAvatar
  }
}
</script>
