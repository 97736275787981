import {
  WEBOS_LUNA_API_DEVICE_INFOMATION,
  WEBOS_LUNA_API_DEVICE_UNIQUE_ID
} from '@/utils/constants'

import _assign from 'lodash/assign'
import _first from 'lodash/first'

export default {
  namespaced: true,

  state: {
    current: null
  },

  mutations: {
    SET_DEVICE (state, device) {
      state.current = _assign(state.current, device)
    }
  },

  actions: {
    FETCH_DEVICE_INFOMATION ({ commit, getters }) {
      if (!getters.isWebOSTV) {
        return Promise.resolve()
      }

      return new Promise((resolve) => {
        webOS.service.request(WEBOS_LUNA_API_DEVICE_INFOMATION, {
          method: 'getSystemInfo',
          parameters: {
            keys: ['modelName', 'sdkVersion'],
          },
          onComplete (r) {
            const isSucceeded = r.returnValue === true

            if (isSucceeded) {
              commit('SET_DEVICE', {
                modelName: r.modelName,
                sdkVersion: r.sdkVersion
              })
            }

            resolve(r)
          }
        })
      })
    },

    FETCH_DEVICE_UNIQUE_ID ({ commit, getters }) {
      if (!getters.isWebOSTV) {
        return Promise.resolve()
      }

      return new Promise((resolve, reject) => {
        webOS.service.request(WEBOS_LUNA_API_DEVICE_UNIQUE_ID, {
          method: 'deviceid/getIDs',
          parameters: {
            idType: ['LGUDID']
          },
          onSuccess (r) {
            commit('SET_DEVICE', { duid: _first(r.idList).idValue })
            resolve(r)
          },
          onFailure (r) {
            reject(r)
          }
        })
      })
    }
  },

  getters: {
    isWebOSTV () {
      return webOS.platform.tv === true
    },

    isWebOSTV4AndLatest (state, getters) {
      if (!getters.isWebOSTV) {
        return false
      }

      const device = state.current
      const version = device && device.sdkVersion ? device.sdkVersion.split('.') : []

      return Number(version[0]) >= 4
    }
  }
}
