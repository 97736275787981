<template>
  <div class="v-player--menu">
    <div class="v-player--menu--title">
      {{ title }}
    </div>

    <div class="v-player--menu--items">
      <v-button
        v-for="item in items"
        :key="item.value"
        :class="{
          'v-player--button--menu': true,
          'is-active': item.value === value
        }"
        hoverColor="outline-primary"
        block
        @click="$emit('menu:changed', item.value)"
      >
        <span slot="icon" class="v-btn--icon"></span> {{ item.label }}
      </v-button>
    </div>
  </div>
</template>

<script>
import VButton from '@/components/VButton'

export default {
  name: 'DialogMenu',

  props: {
    title: String,
    items: {
      type: Array,
      default: () => []
    },
    value: String
  },

  model: {
    prop: 'value',
    event: 'menu:changed'
  },

  components: {
    VButton
  }
}
</script>
