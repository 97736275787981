<template>
  <div class="v-card" :style="styles">
    <slot/>
  </div>
</template>

<script>
import _assignIn from 'lodash/assignIn'
import _forIn from 'lodash/forIn'

export default {
  name: 'VCard',

  props: {
    maxWidth: [Number, String],
    breakpoints: Object
  },

  data () {
    return {
      breakpoint: 0
    }
  },

  computed: {
    options () {
      const options = {}

      if (this.maxWidth) options.maxWidth = this.maxWidth

      return _assignIn(options, this.breakpointOptions)
    },

    breakpointOptions () {
      return (this.breakpoint !== 0 && this.breakpoint in this.breakpoints)
        ? this.breakpoints[this.breakpoint]
        : {}
    },

    styles () {
      const styles = {}

      if (this.options.maxWidth) {
        styles.maxWidth = `${this.options.maxWidth}px`
      }

      return styles
    }
  },

  beforeMount () {
    this._checkBreakpoint()
  },

  mounted () {
    this._addEvents()
  },

  beforeDestroy () {
    this._removeEvents()
  },

  methods: {
    _checkBreakpoint () {
      this.breakpoint = 0

      _forIn(this.breakpoints, (options, breakpoint) => {
        if (window.innerWidth >= breakpoint) {
          this.breakpoint = parseInt(breakpoint)
        }
      })
    },

    _addEvents () {
      window.addEventListener('resize', this.handleResize)
    },

    _removeEvents () {
      window.removeEventListener('resize', this.handleResize)
    },

    handleResize () {
      this._checkBreakpoint()
    },
  }
}
</script>

<style lang="scss">
@import './VCard.scss';
</style>
