import _filter from 'lodash/filter'
import _findIndex from 'lodash/findIndex'
import _forEach from 'lodash/forEach'
import _keys from 'lodash/keys'
import _maxBy from 'lodash/maxBy'

export function getLocale () {
  const lang = localStorage.getItem('lc') || navigator.language
  return /^th/.test(lang) ? 'th' : 'en'
}

export function createSourceTrackPlaylist (streams, streamType = 'hls', streamEncrypt = false) {
  if (typeof streams !== 'object') return

  var sourceTracks = []
  var audios = _filter(_keys(streams), (o) => o !== 'auto')
  var th = _findIndex(audios, 'th')

  if (th !== -1) {
    audios.splice(th, 1)
    audios.unshift('th')
  }

  _forEach(audios, (audio) => {
    var sources = []
    var streamAudio = streams[audio] || []

    if (streamEncrypt) {
      streamAudio = _filter(streamAudio, (o) => o.drm_url !== undefined)
    } else {
      streamAudio = _filter(streamAudio, (o) => o.drm_url === undefined)
    }

    var stream = _maxBy(_filter(streamAudio, { server: process.env.VUE_APP_MONOMAX_STREAM_SERVER }), (o) => o.bitrate === 'auto' ? 9999 : o.bitrate) || _maxBy(streamAudio, (o) => o.bitrate === 'auto' ? 9999 : o.bitrate)

    if (!stream) return

    var source = {
      streamURL: stream.link,
      streamMimeType: streamType === 'dash' ? 'application/dash+xml' : 'application/x-mpegurl'
    }

    if (stream.drm_url !== undefined) {
      source.keySystems = { 'com.widevine.alpha': stream.drm_url }
    }

    sources.push(source)

    sourceTracks.push({
      label: audio,
      language: audio,
      sources
    })
  })

  return sourceTracks
}

export function formatTime (seconds) {
  seconds = seconds < 0 ? 0 : seconds

  var s = Math.floor(seconds % 60)
  var m = Math.floor(seconds / 60 % 60)
  var h = Math.floor(seconds / 3600)
  var gm = Math.floor(seconds / 60 % 60)
  var gh = Math.floor(seconds / 3600)

  if (isNaN(seconds) || seconds === Infinity) {
    h = m = s = '-'
  }

  h = h > 0 || gh > 0 ? h + ':' : ''
  m = ((h || gm >= 10) && m < 10 ? '0' + m : m) + ':'
  s = s < 10 ? '0' + s : s

  return h + m + s
}

export function convertPositionToScale (el, position) {
  if (typeof el === 'undefined') {
    return 0
  }

  var rect = el.getBoundingClientRect()
  var minPosition = rect.left
  var maxPosition = rect.width + rect.left

  if (typeof position === 'undefined' || position < minPosition) {
    position = minPosition
  } else if (position > maxPosition) {
    position = maxPosition
  }

  return (position - rect.left) / rect.width
}

export const keyCodes = {
  left: 37,
  up: 38,
  right: 39,
  down: 40,
  pause: 19,
  stop: [413, 169],
  play: [415, 179],
  enter: 13,
  space: 32,
  rewind: [412, 227],
  forward: [417, 228],
  back: [461, 8],
  number: {
    zero: 48,
    one: 49,
    two: 50,
    three: 51,
    four: 52,
    five: 53,
    six: 54,
    seven: 55,
    eight: 56,
    nine: 57
  }
}
