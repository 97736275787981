<template>
  <transition
    name="fade"
    :mode="mode"
    appear
    @after-enter="$emit('fadein')"
    @after-leave="$emit('fadeout')"
  >
    <slot />
  </transition>
</template>

<script>
export default {
  name: 'VFadeTransition',

  props: {
    mode: {
      type: String,
      default: 'out-in'
    }
  }
}
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity .6s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
