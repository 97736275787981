<template>
  <v-image
    class="app--logo"
    :src="logo"
    :aspectRatio=".25"
    alt="MONOMAX"
  />
</template>

<script>
import VImage from './VImage'

export default {
  name: 'VLogo',

  computed: {
    logo () {
      return require('@/assets/logo.png')
    }
  },

  components: {
    VImage
  }
}
</script>

<style lang="scss">
.app--logo {
  position: fixed;
  top: $spacer * 3;
  right: $spacer * 3;
  width: $logo-width;
  opacity: .3;
  z-index: 9999;
}
</style>
