<template>
  <nav
    :class="classes"
    @transitionend="onTransitionEnd"
    @click.self="isActive = !disabled"
  >
    <slot/>
  </nav>
</template>

<script>
import VOverlay from '@/components/VOverlay.vue'

import Vue from 'vue'

const VOverlayComponent = Vue.extend(VOverlay)

export default {
  name: 'VNavbar',

  props: {
    show: {
      type: Boolean,
      default: true
    },
    disabled: Boolean
  },

  model: {
    prop: 'show',
    event: 'change'
  },

  data () {
    return {
      isActive: false
    }
  },

  computed: {
    classes () {
      return {
        'v-navbar': true,
        'is-hidden': !this.show,
        'is-disabled': this.disabled,
        'is-expanded': this.isActive
      }
    }
  },

  watch: {
    isActive (active) {
      if (active) {
        this._createOverlay()
      } else {
        this._removeOverlay()
      }
    },

    show (show) {
      if (!show) this.isActive = false
    },

    disabled (status) {
      if (status) this.isActive = false
    }
  },

  beforeDestroy () {
    this._removeOverlay()
  },

  methods: {
    _createOverlay () {
      const overlay = new VOverlayComponent().$mount()
      const parent = this.$root.$el

      parent.insertBefore(overlay.$el, parent.firstChild)

      overlay.$el.addEventListener('click', () => this.isActive = false)

      this.$overlay = overlay
    },

    _removeOverlay () {
      if (!this.$overlay) return

      this.$root.$el.removeChild(this.$overlay.$el)

      this.$overlay.$destroy()
      this.$overlay = null
    },

    onTransitionEnd () {
      if (this.show) {
        this.$emit(this.isActive ? 'navbar:expand' : 'navbar:collapse')
      }
    },

    expand (active) { this.isActive = active }
  }
}
</script>

<style lang="scss">
@import './VNavbar.scss';
</style>
