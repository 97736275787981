var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'v-carousel': true,
    'v-carousel--outer': _vm.isSlideOuter,
    'v-carousel--inner': _vm.isSlideInner,
    'v-carousel--vertical': _vm.isVertical,
    'v-carousel--ready': _vm.isReady
  },on:{"mousewheel":_vm.handleMouseWheel}},[_c('div',{ref:"track",staticClass:"v-carousel--track",style:(_vm.trackStyles),on:{"transitionend":function($event){if($event.target !== $event.currentTarget)return null;return _vm.handleTrackMoved.apply(null, arguments)}}},[_vm._t("default")],2),_vm._t("arrow")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }