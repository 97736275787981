<template>
  <div class="v-player--title--display">
    <div
      v-if="title"
      class="v-player--text--title"
    >
      {{ title }}
    </div>
    <div
      v-if="episodeTitle"
      class="v-player--text--subtitle"
    >
      {{ episodeTitle }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TitleDisplay',

  data () {
    return {
      title: null,
      episodeTitle: null
    }
  },

  mounted () {
    this.$parent.$on('player:playlistonload', this.handleDisplay)
  },

  befoerDestroy () {
    this.$parent.$off('player:playlistonload', this.handleDisplay)
  },

  methods: {
    handleDisplay () {
      const player_ = this.$parent
      const { index, playlists } = player_.playback
      const track = playlists.length > 0 ? playlists[index] : null

      if (!track) return

      this.title = track.title
      this.episodeTitle = track.episodeTitle
    }
  }
}
</script>
