<template>
  <div class="v-lottie-player"></div>
</template>

<script>
import Lottie from "lottie-web"

export default {
  name: "VLottiePlayer",

  props: {
    src: String
  },

  mounted () {
    this.$player = Lottie.loadAnimation({
      container: this.$el,
      renderer: 'canvas',
      loop: false,
      autoplay: true,
      path: this.src
    })

    this.$player.addEventListener('complete', () => this.$emit('lottie:ended'))
  },

  beforeDestroy () {
    if (!this.$player) {
      return
    }

    this.$player.removeEventListener('complete')
    this.$player.destroy()
  }
}
</script>
