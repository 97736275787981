var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app",attrs:{"id":"app"}},[_c('div',{staticClass:"app--wrapper"},[_c('v-logo',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLogo),expression:"showLogo"}]}),_c('v-navbar',{directives:[{name:"sn",rawName:"v-sn",value:(_vm.snOptions.navbar),expression:"snOptions.navbar"}],ref:"navbar",attrs:{"disabled":!_vm.activeNavbar},on:{"navbar:expand":_vm.handleNavbarExpand,"navbar:collapse":_vm.handleNavbarCollapse},model:{value:(_vm.showNavbar),callback:function ($$v) {_vm.showNavbar=$$v},expression:"showNavbar"}},[_c('ul',{staticClass:"nav v-navbar--nav"},[(_vm.isAuthenticated)?_c('li',{staticClass:"nav-item nav-item--member"},[_c('router-link',{directives:[{name:"sn-focusable",rawName:"v-sn-focusable"}],staticClass:"nav-link",attrs:{"to":{ name: 'profile-choose' }},nativeOn:{"sn:willmove":function($event){return _vm.handleNavbarMenuBeforeDeactive.apply(null, arguments)},"sn:deactive":function($event){return _vm.handleNavbarMenuDeactive.apply(null, arguments)},"sn:active":function($event){return _vm.handleNavbarMenuActive.apply(null, arguments)}}},[_c('span',{staticClass:"nav-link--icon"},[(_vm.user.avatarUrl)?_c('span',{staticClass:"nav-link--icon--img"},[_c('img',{attrs:{"src":_vm.user.avatarUrl,"alt":_vm.user.name}})]):_c('v-icon',{attrs:{"icon":"circle-user"}})],1),_c('span',{staticClass:"nav-link--label"},[_vm._v(" "+_vm._s(_vm.user.name)+" ")])])],1):_vm._e(),_c('li',{staticClass:"nav-item nav-item--home"},[_c('a',{directives:[{name:"sn-focusable",rawName:"v-sn-focusable",value:({ auto: false }),expression:"{ auto: false }"}],class:{
              'nav-link': true,
              'is-active': _vm.isHome
            },attrs:{"href":""},on:{"sn:willmove":_vm.handleNavbarMenuBeforeDeactive,"sn:deactive":_vm.handleNavbarMenuDeactive,"sn:active":_vm.handleNavbarMenuActive,"click":function($event){$event.preventDefault();return _vm.handleNavigateHome.apply(null, arguments)}}},[_c('span',{staticClass:"nav-link--icon"},[_c('v-icon',{attrs:{"icon":"house"}})],1),_c('span',{staticClass:"nav-link--label"},[_vm._v(" "+_vm._s(_vm.$t('menu.home'))+" ")])])]),_vm._l((_vm.menus),function(menu,key){return [(!menu.auth || menu.auth === _vm.isAuthenticated)?_c('li',{key:key,class:[
              'nav-item',
              `nav-item--${menu.path.name}`,
              menu.name === 'menu.inbox' && _vm.activeMessageUnread ? 'is-active' : ''
            ]},[_c('router-link',{directives:[{name:"sn-focusable",rawName:"v-sn-focusable"}],staticClass:"nav-link",attrs:{"to":menu.path,"replace":menu.replace && !_vm.isHome},nativeOn:{"sn:willmove":function($event){return _vm.handleNavbarMenuBeforeDeactive.apply(null, arguments)},"sn:deactive":function($event){return _vm.handleNavbarMenuDeactive.apply(null, arguments)},"sn:active":function($event){return _vm.handleNavbarMenuActive.apply(null, arguments)}}},[_c('span',{staticClass:"nav-link--icon"},[_c('v-icon',{attrs:{"icon":menu.icon}})],1),_c('span',{staticClass:"nav-link--label"},[_vm._v(" "+_vm._s(_vm.$t(menu.name))+" ")])])],1):_vm._e()]})],2)]),_c('main',{staticClass:"main"},[(_vm.showLottieLoading)?_c('div',{staticClass:"app--splash-background"},[_c('v-lottie-player',{attrs:{"src":"/splash-background.json"},on:{"lottie:ended":_vm.handleSplashBackgroundEnded}})],1):_c('router-view',{staticClass:"main--wrapper"})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }