import {
  login,
  logout,
  checkPassword,
  fetchDeviceLogin,
  fetchDeviceToken,
  fetchUser,
  setToken,
  refreshToken,
  removeToken,
  removeProfile,
  checkConsentUpdate,
  acceptConsent
} from '../api'

const namespaced = true

const state = {
  token: null,
  consent: {
    checked: false,
    detail: null
  }
}

const mutations = {
  SET_TOKEN (state, token) {
    state.token = token
  },

  SET_CONSENT_CHECKED (state, checked) {
    state.consent.checked = checked
  },

  SET_CONSENT_DETAIL (state, detail) {
    state.consent.detail = detail
  },

  RESET_STATE (state) {
    state.token = null
    state.consent.checked = false
    state.consent.detail = null
  }
}

const actions = {
  ENSURE_ACCESS_TOKEN ({ state, commit }) {
    const d = new Date()
    const now = Math.floor(d.getTime() / 1000)
    const expire = state.token ? state.token.expires_in - 86400 : null

    if (!expire || now < expire) {
      return Promise.resolve()
    }

    return refreshToken(state.token.refresh_token)
      .then(res => res.data)
      .then(token => {
        localStorage.setItem('mnmaxToken', JSON.stringify(token))
        setToken(token.access_token)
        commit('SET_TOKEN', token)
      })
  },

  SIGNIN ({ commit, dispatch }, { username, password }) {
    return login(username, password)
      .then(res => res.data)
      .then(token => {
        localStorage.setItem('mnmaxToken', JSON.stringify(token))
        setToken(token.access_token)
        commit('SET_TOKEN', token)
      })
      .then(() => dispatch('FETCH_USER'))
      .then(() => dispatch('CHECK_CONSENT_UPDATE'))
      .then(() => dispatch('user/CHECK_MESSAGE_UNREAD', null, { root: true }))
      .then(() => dispatch('user/CHOOSE_PROFILE', {}, { root: true }))
  },

  CHECK_AUTH ({ commit, dispatch, getters }) {
    const token = JSON.parse(localStorage.getItem('mnmaxToken'))
    const index = Number(localStorage.getItem('mnmaxUser'))

    if (!token || getters.isAuthenticated) {
      return Promise.resolve()
    }

    setToken(token.access_token)

    commit('SET_TOKEN', token)

    return dispatch('FETCH_USER')
      .then(() => dispatch('CHECK_CONSENT_UPDATE'))
      .then(() => dispatch('user/CHECK_MESSAGE_UNREAD', null, { root: true }))
      .then(() => dispatch('user/CHOOSE_PROFILE', { index }, { root: true }))
      .catch((e) => {
        const error = e.response || {}

        if (error.status === 400 || error.status === 401) {
          dispatch('LOGOUT')
        }
      })
  },

  CHECK_DEVICE_LOGIN ({ commit, dispatch }, { deviceToken }) {
    return fetchDeviceLogin(deviceToken)
      .then(res => res.data)
      .then(token => {
        localStorage.setItem('mnmaxToken', JSON.stringify(token))
        setToken(token.access_token)
        commit('SET_TOKEN', token)
      })
      .then(() => dispatch('FETCH_USER'))
      .then(() => dispatch('CHECK_CONSENT_UPDATE'))
      .then(() => dispatch('user/CHECK_MESSAGE_UNREAD', null, { root: true }))
      .then(() => dispatch('user/CHOOSE_PROFILE', {}, { root: true }))
  },

  CHECK_PASSWORD ({ rootState }, { password }) {
    const user = rootState.user.current

    if (user === null) {
      return Promise.reject()
    } else {
      return checkPassword(user.username, password)
    }
  },

  FETCH_DEVICE_TOKEN () {
    return fetchDeviceToken()
      .then((res) => res.data)
  },

  FETCH_USER ({ commit, dispatch }) {
    return dispatch('ENSURE_ACCESS_TOKEN')
      .then(() => fetchUser())
      .then(res => res.data)
      .then(user => commit('user/SET_USER', user, { root: true }))
  },

  CHECK_CONSENT_UPDATE ({ state, commit }) {
    return checkConsentUpdate()
      .then((detail) => commit('SET_CONSENT_DETAIL', detail))
      .then(() => {
        const { detail } = state.consent
        const checked = !(detail.accepted === null || detail.accepted < detail.current)

        commit('SET_CONSENT_CHECKED', checked)
      })
  },

  ACCEPT_CONSENT ({ commit }, { version }) {
    return acceptConsent(version)
      .then(() => commit('SET_CONSENT_CHECKED', true))
  },

  LOGOUT ({ commit }) {
    logout()

    localStorage.removeItem('mnmaxToken')
    localStorage.removeItem('mnmaxUser')

    removeToken()
    removeProfile()

    commit('RESET_STATE')
    commit('user/CLEAR_USER_DATA', null, { root: true })
  }
}

const getters = {
  isAuthenticated (state, getters, rootState) {
    return !!state.token && !!rootState.user.current
  },

  isConsentChecked (state) {
    return state.consent.checked
  }
}

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
