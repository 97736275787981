<template>
  <v-button
    class="v-player--button--play-toggle px-0"
    hoverColor="primary"
    rounded
    @keydown.native="handleKeyDown"
    @keyup.native="hasKeyDown = false"
    @click="handleClick"
  >
    <span slot="icon" class="v-btn--icon"></span>
  </v-button>
</template>

<script>
import VButton from '@/components/VButton'

import { keyCodes } from '@/utils/helpers'

export default {
  name: 'PlayToggleButton',

  data () {
    return {
      hasKeyDown: false
    }
  },

  components: {
    VButton
  },

  methods: {
    handleKeyDown (e) {
      const keycode = e.keyCode || e.which

      if (keycode === keyCodes.enter || keycode === keyCodes.space) {
        e.preventDefault()

        if (this.hasKeyDown) return

        this.hasKeyDown = true
        this.handleClick()
      }
    },

    handleClick (e) {
      const player_ = this.$parent

      if (player_.isVideoUnload || player_.isVideoError) {
        return
      }

      player_.userActive(true)
      player_.playOrPause()

      this.$emit('click', e)
    }
  }
}
</script>
