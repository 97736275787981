<template>
  <v-fade-transition>
    <div
      v-show="show"
      :class="{
        'v-navigation': true,
        'hide-vertical': this.hideVertical,
        'hide-horizontal': this.hideHorizontal
      }"
    >
      <slot>
        <v-button
          v-for="key in keys"
          :key="key"
          color="primary"
          :class="['v-btn--arrow', `v-btn--arrow--${key}`]"
          :icon="`chevron-${key}`"
          rounded
          @click="$sn.move(key)"
          :ref="key"
        />
      </slot>
    </div>
  </v-fade-transition>
</template>

<script>
import VButton from '@/components/VButton'
import VFadeTransition from '@/transitions/VFadeTransition.vue'

export default {
  name: 'VNavigation',

  props: {
    show: Boolean,
    direction: String
  },

  model: {
    prop: 'show',
    event: 'navigation:toggle'
  },

  data () {
    return {
      hideVertical: false,
      hideHorizontal: false
    }
  },

  computed: {
    isVertical () {
      return this.direction === 'vertical'
    },

    isHorizontal () {
      return this.direction === 'horizontal'
    },

    keys () {
      if (this.isVertical) {
        return ['up', 'down']
      } else if (this.isHorizontal) {
        return ['left', 'right']
      } else {
        return ['up', 'down', 'left', 'right']
      }
    }
  },

  components: {
    VButton,
    VFadeTransition
  },

  methods: {
    toggle (display, direction) {
      if (!display) display = 'show'

      if (direction === 'vertical') {
        this.hideVertical = display === 'hide' ? true : false
      } else if (direction === 'horizontal') {
        this.hideHorizontal = display === 'hide' ? true : false
      }
    }
  }
}
</script>

<style lang="scss">
@import './VNavigation.scss';
</style>
