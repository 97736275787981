<template>
  <div
    v-show="showDialog"
    class="v-player--dialog"
  >
    <div
      v-if="closeable"
      class="v-player--dialog--backdrop"
      @click.self="toggle(false)"
    >
    </div>

    <div class="v-player--dialog--container">
      <v-button
        v-if="closeable"
        class="v-player--dialog--close"
        hoverColor="primary"
        rounded
        @click="toggle(false)"
      >
        <span slot="icon" class="v-btn--icon"></span>
      </v-button>

      <slot />
    </div>
  </div>
</template>

<script>
import VButton from '@/components/VButton'

export default {
  name: 'Dialog',

  props: {
    show: Boolean,
    closeable: {
      type: Boolean,
      default: true
    }
  },

  model: {
    prop: 'show',
    event: 'dialog:toggle'
  },

  data () {
    return {
      showDialog: false
    }
  },

  watch: {
    show (show) {
      this.showDialog = show
    },

    showDialog (show) {
      this.$emit('dialog:toggle', show)
    }
  },

  components: {
    VButton
  },

  methods: {
    toggle (show) {
      this.showDialog = typeof show !== 'undefined' ? show : !!this.show
    }
  }
}
</script>
