import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { getLocale } from '@/utils/helpers'

import en from './_en'
import th from './_th'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: getLocale(),
  messages: { en, th }
})

export default i18n
