import Pusher from 'pusher-js'

import _merge from 'lodash/merge'

const VuePusher = function (options) {
  this._options = options
  this.pusher_ = null

  this.init()
}

VuePusher.prototype.init = function () {
  const { apiKey, options } = this._options

  if (this.pusher_ && this.pusher_.connection.state === 'connected') {
    this.pusher_.disconnect()
    this.pusher_ = null
  }

  this.pusher_ = new Pusher(apiKey, options)
}

VuePusher.prototype.setOptions = function (options) {
  this._options.options = _merge(this._options.options, options)
  this.init()
}

export default {
  install (Vue, options) {
    Pusher.isReady = !!options.autoConnect
    Pusher.logToConsole = process.env.NODE_ENV !== 'production'

    const pusher = new VuePusher(options)

    Vue.prototype.$pusher = pusher
  }
}
