<template>
  <header class="v-header v-header--hero product-preview">
    <div class="v-header--container">
      <template v-if="item">
        <div class="v-header--title h2">
          {{ item.title }}
        </div>

        <div class="v-header--subtitle d-flex">
          <span class="label label--kind py-1 bg-primary">
            {{ convertKindToText(item.kind) }}
          </span>

          <span
            v-if="item.kind === 'series'"
            class="label label--episodes px-0 py-1"
          >
            {{ $t('product.metas.episodes', { count: item.num_episodes }) }}
          </span>

          <span
            v-else-if="item.kind === 'movie'"
            class="label label--duration px-0 py-1"
          >
            {{ $t('product.metas.duration', { minutes: item.duration }) }}
          </span>

          <span class="label labe--rate px-0 py-1">
            {{ $t('product.metas.rate', { rate: convertRateToText(item.rate) }) }}
          </span>
        </div>

        <div class="v-header--content">
          {{ item.description }}
        </div>
      </template>
    </div>

    <slot />
  </header>
</template>

<script>
import convertable from '@/mixins/convertable.js'

export default {
  name: 'ProductPreview',

  mixins: [convertable],

  props: {
    item: Object
  }
}
</script>
