import Vue from 'vue'
import VueX from 'vuex'

import {
  fetchHome,
  fetchHomeGenre,
  fetchHomeOrigin,
  fetchHomeUpcoming,
  fetchHomeShortClips,
  fetchTypeNav,
  fetchGenreItems,
  fetchLiveTVPrograms,
  fetchCategoryItems,
  fetchTagItems,
  fetchPeopleItems,
  fetchProduct,
  fetchProductTrailer
} from '../api'

import device from './device'
import auth from './auth'
import forgotPassword from './forgot-password'
import product from './product'
import player from './player'
import user from './user'
import profile from './profile'

import _concat from 'lodash/concat'
import _filter from 'lodash/filter'
import _find from 'lodash/find'
import _forEach from 'lodash/forEach'
import _map from 'lodash/map'
import _maxBy from 'lodash/maxBy'

Vue.use(VueX)

export default new VueX.Store({
  modules: {
    device,
    auth,
    forgotPassword,
    product,
    player,
    user,
    profile
  },

  state: {
    activePlatformBack: false,
    activePlatformCursor: false
  },

  mutations: {
    SET_ACTIVE_PLATFORM_BACK (state, active) {
      state.activePlatformBack = active
    },

    SET_ACTIVE_PLATFORM_CURSOR (state, active) {
      state.activePlatformCursor = active
    },

    SET_DEVICE (state, device)  {
      state.device = device
    }
  },

  actions: {
    FETCH_HOME ({ getters }, { type }) {
      return fetchHome(type)
        .then((res) => res.data)
        .then((lists) => {
          const activeListTypes = ['history', 'product', 'people', 'shortclips']

          var newLists = []

          _forEach(lists, (o) => {
            const isActiveType = activeListTypes.indexOf(o.type) !== -1
            const hasItems = (typeof o.items !== 'undefined' && o.items.length > 0)

            if (!isActiveType || !hasItems) {
              return
            }

            if (!getters.isAllowedAdultContents) {
              o.items = _filter(o.items || [], (i) => {
                var rate = typeof i.product !== 'undefined' ? i.product.rate : i.rate;
                return rate !== 'RATE_18' && rate !== 'RATE_20'
              });
            }

            if (o.items.length) {
              newLists.push(o)
            }
          })

          const topListMappings = ['product-series', 'history']

          var topLists = []

          _forEach(topListMappings, (slug) => {
            var list = _find(newLists, { slug })

            if (typeof list !== 'undefined') {
              topLists.push(list);
            }
          })

          newLists = _concat(topLists, _filter(newLists, (list) => topListMappings.indexOf(list.slug) === -1))

          return newLists
        })
    },

    FETCH_HOME_GENRE ({ getters }, { type, genre }) {
      return fetchHomeGenre(type, genre)
        .then((res) => res.data)
        .then((lists) => _filter(lists || [], (o) => o.type === 'product'))
        .then((lists) => {
          const newLists = []

          _forEach(lists, (o) => {
            o.items = o.items || []

            if (!getters.isAllowedAdultContents) {
              o.items = _filter(o.items, (i) => i.rate !== 'RATE_18' && i.rate !== 'RATE_20')
            }

            if (o.items.length) newLists.push(o)
          })

          return newLists
        })
    },

    FETCH_HOME_ORIGIN ({ getters }, { type, origin }) {
      return fetchHomeOrigin(type, origin)
        .then((res) => res.data)
        .then((lists) => _filter(lists || [], (o) => o.type === 'product'))
        .then((lists) => {
          const newLists = []

          _forEach(lists, (o) => {
            o.items = o.items || []

            if (!getters.isAllowedAdultContents) {
              o.items = _filter(o.items, (i) => i.rate !== 'RATE_18' && i.rate !== 'RATE_20')
            }

            if (o.items.length) newLists.push(o)
          })

          return newLists
        })
    },

    FETCH_HOME_UPCOMING (ctx, { page, limit }) {
      return fetchHomeUpcoming(page, limit)
        .then((res) => res.data)
    },

    FETCH_HOME_SHORTCLIPS ({ getters }, { page, limit }) {
      return fetchHomeShortClips(page, limit)
        .then((res) => res.data || [])
        .then((items) => {
          return !getters.isAllowedAdultContents
            ? _filter(items, (i) => i.product.rate !== 'RATE_18' && i.product.rate !== 'RATE_20')
            : items
        })
    },

    FETCH_HOME_LIVETV () {
      return fetchHome('livetv')
        .then((res) => res.data)
        .then(({ channels }) => _map(channels, (channel) => ({ ...channel, programs: [] })))
    },

    FETCH_HOME_LIVESPORTS ({ getters }) {
      return fetchHome('live')
        .then((res) => res.data)
        .then((lists) => {
          if (lists && !lists.length) {
            return
          }

          const newLists = []
          const upcoming = _find(lists, { type: 'upcoming' })
          const livestream = _find(lists, { type: 'current' })
          const vod = _find(lists, { type: 'vod' })

          // Live Programs
          if ((livestream && livestream.items.length) || (upcoming && upcoming.items.length)) {
            const liveprograms = {
              title: 'Live Programs',
              type: 'live'
            }

            const now = Math.floor((new Date()).getTime() / 1000)

            liveprograms.items = _map(_concat(livestream.items, upcoming.items), (item) => {
              const start = (new Date(item.start)).getTime()
              const end = (new Date(item.end)).getTime()

              if (!Number.isNaN(start) && !Number.isNaN(end)) {
                const isLive = now >= Math.floor(start / 1000) && now <= Math.floor(end / 1000)
                item.status = isLive ? 'live' : 'upcoming'
              } else {
                item.status = 'upcoming'
              }

              item.kind = 'live'

              return item
            })

            liveprograms.items.length && newLists.push(liveprograms)
          }

          // VOD
          _forEach(vod.items || [], (o) => {
            if (!getters.isAllowedAdultContents) {
              o.items = _filter(o.items || [], (i) => i.rate !== 'RATE_18' && i.rate !== 'RATE_20')
            }

            o.items.length && newLists.push(o)
          })

          return newLists
        })
    },

    FETCH_KIND_NAV (ctx, { type }) {
      if (typeof type === 'undefined') {
        return Promise.resolve([])
      } else {
        return fetchTypeNav(type)
          .then((res) => _filter(res.data || [], (o) => {
            return o.more && ['tag', 'category', 'origin-home', 'genre-home', 'origin-genre'].indexOf(o.more.page) >= 0
          }))
      }
    },

    FETCH_GENRE_DETAIL (ctx, { origin, genre, productId }) {
      return fetchProduct(productId)
        .then((res) => res.data)
        .then((product) => ({
          origin: _find(product.origins_list || [], (o) => o.slug === origin),
          genre: _find(product.genres_list || [], (o) => o.slug === genre)
        }))
    },

    FETCH_GENRE_ITEMS ({ getters }, { type, origin, genre, page, limit }) {
      return fetchGenreItems(type, origin, genre, page, limit)
        .then((res) => res.data)
        .then((items) => {
          return !getters.isAllowedAdultContents
            ? _filter(items, (i) => i.rate !== 'RATE_18' && i.rate !== 'RATE_20')
            : items
        })
    },

    FETCH_CATEGORY_ITEMS ({ getters }, { slug, page, limit }) {
      return fetchCategoryItems(slug, page, limit)
        .then((res) => res.data)
        .then((items) => {
          return !getters.isAllowedAdultContents
            ? _filter(items, (i) => i.rate !== 'RATE_18' && i.rate !== 'RATE_20')
            : items
        })
    },

    FETCH_TAG_ITEMS ({ getters }, { slug, page, limit }) {
      return fetchTagItems(slug, page, limit)
        .then((res) => res.data)
        .then((items) => {
          return !getters.isAllowedAdultContents
            ? _filter(items, (i) => i.rate !== 'RATE_18' && i.rate !== 'RATE_20')
            : items
        })
    },

    FETCH_PEOPLE_DETAIL (ctx, { slug, productId }) {
      return fetchProduct(productId)
        .then((res) => res.data)
        .then((product) => {
          const actor = _find(product.actors_list, { slug })
          const director = _find(product.directors_list, { slug })

          return actor || director
        })
    },

    FETCH_PEOPLE_ITEMS ({ getters }, { slug, page, limit }) {
      return fetchPeopleItems(slug, page, limit)
        .then((res) => res.data)
        .then((items) => {
          return !getters.isAllowedAdultContents
            ? _filter(items, (i) => i.rate !== 'RATE_18' && i.rate !== 'RATE_20')
            : items
        })
    },

    FETCH_LIVETV_PROGRAMS (ctx, { id }) {
      return fetchLiveTVPrograms(id)
        .then((res) => res.data)
        .then((programs) => programs && programs.length ? programs[0].items : [])
    },

    FETCH_PRODUCT_TRAILER (ctx, { id }) {
      if (!id) {
        return Promise.resolve([])
      } else {
        return fetchProductTrailer(id)
          .then((res) => res.data)
          .then((items) => _filter(_map(items || [], (item) => {
            var streamHls = (item.streams ? item.streams.hls : null) || {}
            var streamHlsAuto = _filter(streamHls.auto || [], (o) => o.drm_url === undefined)
            var stream = _maxBy(_filter(streamHlsAuto, { server: process.env.VUE_APP_MONOMAX_STREAM_SERVER }), (o) => o.bitrate === 'auto' ? 9999 : o.bitrate) || _maxBy(streamHlsAuto, (o) => o.bitrate === 'auto' ? 9999 : o.bitrate)

            return stream
              ? {
                id: item.id,
                title: item.title,
                sources: [{
                  streamURL: stream.link,
                  streamMimeType: 'application/x-mpegurl'
                }]
              }
              : null
          }), _ => _))
      }
    }
  },

  getters: {
    isDrmSupported (state, getters, rootState, rootGetters) {
      const isWebOSTV = rootGetters['device/isWebOSTV']
      const device = state.device.current

      if (!isWebOSTV || !device) {
        return true // if its not a tv device then force play the drm content.
      }

      const version = device.sdkVersion.split('.')

      return Number(version[0]) >= 3
    },

    isAllowedAdultContents (state, getters, rootState, rootGetters) {
      const isAuthenticated = rootGetters['auth/isAuthenticated']
      const isPackageRegistered = rootGetters['user/isPackageRegistered']
      const isPackageExpired = rootGetters['user/isPackageExpired']

      return isAuthenticated && isPackageRegistered && !isPackageExpired
    }
  }
})
