<script>
import HomeList from './views/HomeList.vue'

export default {
  name: 'Home',

  extends: HomeList,

  props: {
    type: String
  },

  beforeMount () {
    this._loadNavList()
  },

  methods: {
    _loadList () {
      return this.$store.dispatch('FETCH_HOME', { type: this.type })
        .then((lists) => {
          this.lists = lists
        }).catch(() => {
          this.$router.push({ name: 'error' })
        })
    },

    _loadNavList () {
      if (this.type === 'home') {
        return
      }

      return this.$store.dispatch('FETCH_KIND_NAV', { type: this.type })
        .then((navs) => this.navs = navs)
    }
  }
}
</script>
