<template>
  <i class="v-icon">
    <font-awesome-icon
      v-bind="{
        icon: icon,
        ...options
      }"
    />
  </i>
</template>

<script>
export default {
  name: 'VIcon',

  props: {
    icon: [String, Array],
    options: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style lang="scss">
@import './VIcon.scss';
</style>
