import moment from 'moment'
import 'moment/locale/th'
import { getLocale } from '@/utils/helpers'

moment.locale(getLocale())

const VueMoment = {
  install (Vue) {
    Object.defineProperties(Vue.prototype, {
      $moment: {
        get () { return moment }
      }
    })

    Vue.filter('localTime', function (time, format) {
      const locale = moment.locale()
      const years = ['th', 'th-TH'].indexOf(locale) !== -1 ? 543 : 0

      return moment(time).add(years, 'years').format(format)
    })
  }
}

export default VueMoment
