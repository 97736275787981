const state = {
  index: null,
  native: false,
  position: null,
  subtitle: null,
  audio: null,
  playlist: []
}

const mutations = {
  SET_NATIVE (state, active) {
    state.native = !!active
  },

  SET_INDEX (state, index) {
    state.index = index
  },

  SET_POSITION (state, position) {
    state.position = position
  },

  SET_SUBTITLE (state, subtitle) {
    state.subtitle = subtitle
  },

  SET_AUDIO (state, audio) {
    state.audio = audio
  },

  SET_PLAYLIST (state, playlist) {
    state.playlist = playlist || []
  },

  RESET_STATE (state) {
    state.index = null
    state.native = false
    state.position = null
    state.subtitle = null
    state.audio = null
    state.playlist = []
  }
}

const getters = {
  isPlaylistEmpty (state) {
    return !state.playlist.length
  }
}

const player = {
  namespaced: true,
  state,
  mutations,
  getters
}

export default player
