<template>
  <v-button
    class="v-player--button--big-play"
    color="primary"
    rounded
    @click="handleClick"
  >
    <span slot="icon" class="v-btn--icon"></span>
  </v-button>
</template>

<script>
import VButton from '@/components/VButton'

export default {
  name: 'BigPlayButton',

  components: {
    VButton
  },

  methods: {
    handleClick (e) {
      const player_ = this.$parent
      const playlists = player_.getPlaylists()

      if (playlists.length === 0 || player_.isVideoUnload || player_.isVideoError) {
        return
      }

      player_.play()

      this.$emit('click', e)
    }
  }
}
</script>
