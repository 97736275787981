<template>
  <div
    :class="{
      'v-carousel': true,
      'v-carousel--outer': isSlideOuter,
      'v-carousel--inner': isSlideInner,
      'v-carousel--vertical': isVertical,
      'v-carousel--ready': isReady
    }"
    @mousewheel="handleMouseWheel"
  >
    <div
      class="v-carousel--track"
      :style="trackStyles"
      @transitionend.self="handleTrackMoved"
      ref="track"
    >
      <slot />
    </div>

    <slot name="arrow" />
  </div>
</template>

<script>
import _filter from 'lodash/filter'
import _forEach from 'lodash/forEach'
import _map from 'lodash/map'

export default {
  name: 'VCarousel',

  props: {
    perPage: {
      type: [String, Number],
      default: 1
    },
    perMove: {
      type: [String, Number],
      default: 1
    },
    gutter: {
      type: [String, Number],
      default: 20
    },
    padding: {
      type: [String, Number],
      default: 0
    },
    preloadPage: {
      type: [String, Number],
      default: 1
    },
    preloadBy: {
      type: String,
      default: 'self'
    },
    direction: {
      type: String,
      default: 'horizontal'
    },
    nested: {
      type: String,
      default: ''
    },
    breakpoints: {
      type: Object,
      default: () => ({})
    },
    start: {
      type: [String, Number],
      default: 0
    },
    centered: Boolean,
    wheel: Boolean,
    arrows: Boolean,
    lazyload: Boolean
  },

  data () {
    return {
      isReady: false,
      isMove: false,
      isWheeling: false,
      isFirstMove: false,
      rootRect: null,
      trackSize: 0,
      trackTranslateX: 0,
      trackTranslateY: 0,
      slidePositions: [],
      slides: [],
      images: [],
      index: 0,
      breakpoint: 0
    }
  },

  computed: {
    options () {
      return Object.assign({
        perPage: Number(this.perPage),
        perMove: Number(this.perMove),
        gutter: Number(this.gutter),
        padding: Number(this.padding),
        preloadPage: Number(this.preloadPage)
      }, this.breakpointOptions)
    },

    breakpointOptions () {
      return this.breakpoints
        ? this.breakpoints[this.breakpoint]
        : {}
    },

    isSlideOuter () {
      return this.nested === 'outer'
    },

    isSlideInner () {
      return this.nested === 'inner'
    },

    isVertical () {
      return this.direction === 'vertical'
    },

    trackStyles () {
      const { padding } = this.options

      return {
        width: !this.isVertical ? `${this.trackSize}px` : null,
        height: this.isVertical ? `${this.trackSize}px` : null,
        padding: padding > 0 ? `${padding}px` : null,
        transform: `translate3d(${this.trackTranslateX}px, ${this.trackTranslateY}px, 0px)`,
        transitionDuration: !this.isFirstMove ? '0s' : null
      }
    },

    offset () {
      return this.centered ? Math.floor(this.options.perPage / 2) : 0
    },

    range () {
      const perPage = this.options.perPage
      const slideCount = this.slides.length

      var start = this.index
      var end = start + perPage - 1

      if (this.centered && !this.isVertical) {
        var offset = perPage % 2 === 0 ? this.offset - 1 : this.offset

        start -= offset
        end = this.index + offset

        if (start > slideCount - perPage) {
          start = this.index - (perPage - (this.finalIndex - this.index) - 1)
        } else if (end < perPage) {
          end = this.index + ((perPage - 1) - this.index)
        }
      }

      start = Math.max(start, 0)
      end = Math.min(end, slideCount - 1)

      return { start, end }
    },

    finalIndex () {
      const slideCount = this.slides.length
      return this.centered ? slideCount - 1 : slideCount - this.options.perPage
    },

    prevIndex () {
      return this.index > 0 ? this.index - this.options.perMove : 0
    },

    nextIndex () {
      return this.index < this.finalIndex ? this.index + this.options.perMove : this.finalIndex
    },
  },

  beforeMount () {
    this._bindEvents()
  },

  mounted () {
    this.index = Number(this.start)
    this._setup()

    this.$nextTick(() => {
      this.isReady = true
      this.$emit('carousel:ready')
    })
  },

  beforeDestroy () {
    this._unbindEvents()
  },

  methods: {
    _setup () {
      this.slides = [].slice.call(this.$refs.track.children)

      if (this.slides.length === 0) {
        return
      }

      this._resize()
      this._slideUpdate()

      this.lazyload && this._lazyLoad()
    },

    _resize () {
      this._checkBreakpoint()

      const newRect = this.$el.getBoundingClientRect()
      const hasRootRect = !!this.rootRect

      if (!hasRootRect || this.rootRect.width !== newRect.width || this.rootRect.height !== newRect.height) {
        const { gutter, padding, perPage } = this.options
        const slideCount = this.slides.length
        const slideWidth = (((newRect.width - (padding * 2)) + gutter) / perPage) - gutter

        var slidePositions = []
        var trackSize = 0

        _forEach(this.slides, (slide, index) => {
          const isLastItem = index === slideCount - 1
          const slideRect = slide.getBoundingClientRect()
          const slideSize = this.isVertical ? slideRect.height : slideWidth

          if (this.isVertical) {
            slide.style.marginBottom = !isLastItem ? `${gutter}px` : null
          } else {
            slide.style.width = `${slideSize}px`
            slide.style.marginRight = !isLastItem ? `${gutter}px` : null
          }

          slidePositions.push(trackSize)

          trackSize += isLastItem ? slideSize : slideSize + gutter
        })

        if (this.centered && !this.isVertical) {
          const offsetEnd = this.finalIndex - this.offset

          slidePositions  = _map(slidePositions, (position, index) => {
            if (index < this.offset || this.slides.length <= this.perPage) {
                position = 0
            } else if (index > offsetEnd) {
                position = trackSize - newRect.width
            } else {
                position -= (newRect.width - (slideWidth + gutter)) / 2
            }

            return position
          })
        }

        this.trackSize = trackSize
        this.slidePositions = slidePositions
        this.rootRect = newRect

        this._toPosition(this.index, true)

        this.$emit('carousel:resize')
      }
    },

    _toPosition (index, silent) {
      if (typeof index === 'undefined' || index < 0) {
        index = 0
      } else if (index > this.finalIndex) {
        index = this.finalIndex
      }

      this.isMove = !silent
      this.index = index

      if (this.isMove) {
        this.isFirstMove = true
        this.$emit('carousel:move')
      }

      var position = this.slidePositions[index]

      if (this.isVertical) {
        this.trackTranslateY = -position
      } else {
        this.trackTranslateX = -position
      }
    },

    _slideUpdate () {
      const perMove = this.options.perMove
      const rangeStart = this.range.start - perMove
      const rangeEnd = this.range.end + perMove

      _forEach(this.slides, (slide, index) => {
        // update a slide can visible.
        if (index >= rangeStart && index <= rangeEnd) {
          slide.classList.add('is-visible')
        } else {
          slide.classList.remove('is-visible')
        }

        // update if a slide is a previous or next slide.
        if (index === rangeStart) {
          slide.classList.add('v-carousel--slide--prev')
        } else {
          slide.classList.remove('v-carousel--slide--prev')
        }

        if (index === rangeEnd) {
          slide.classList.add('v-carousel--slide--next')
        } else {
          slide.classList.remove('v-carousel--slide--next')
        }
      })
    },

    _checkBreakpoint () {
      var newBreakpoint = 0

      for (const breakpoint in this.breakpoints) {
        if (window.innerWidth >= Number(breakpoint)) {
          newBreakpoint = Number(breakpoint)
        }
      }

      this.breakpoint = newBreakpoint
    },

    _lazyLoad () {
      const { start, end } = this.range
      const startIndex = start - (this.perMove * 2)
      const finalIndex = end + (this.perMove * 2)

      _forEach(this.slides, (slide, slideIndex) => {
        const selectors = this.isSlideOuter || this.isSlideInner ? `[data-${this.nested}-lazy], [data-lazy]` : '[data-lazy]'
        const images = slide.querySelectorAll(selectors)

        _forEach(images, (img) => {
          if (this.nested) {
            var imgLazy = img.dataset.lazy

            if (this.isSlideOuter) {
              imgLazy = img.dataset.outerLazy = img.dataset.outerLazy || imgLazy
            } else if (this.isSlideInner) {
              if (this.preloadBy === 'outer' && slideIndex >= startIndex && slideIndex <= finalIndex) {
                img.dataset.outerLazy = img.dataset.outerLazy || imgLazy
                img.removeAttribute('data-lazy')
                return
              } else {
                imgLazy = img.dataset.innerLazy = img.dataset.innerLazy || imgLazy
              }
            }

            if (imgLazy) {
              this.images.push({ index: slideIndex, img })
            }

            img.removeAttribute('data-lazy')
          } else {
            img.classList.add('img-lazy')
            this.images.push({ index: slideIndex, img })
          }
        })
      })

      this._lazyLoadImage()

      this.$on('carousel:moved', this._lazyLoadImage)
    },

    _lazyLoadImage () {
      const { perMove, perPage, preloadPage } = this.options
      const rangeStart = this.range.start - (perMove * 2)
      const rangeEnd = this.range.start + ((preloadPage * perPage) - 1) + (perMove * 2)

      var imgEl = null, imgSrc = null

      this.images = _filter(this.images, ({ img, index }) => {
        if (imgEl === null && index >= rangeStart && index <= rangeEnd) {
          imgEl = img
          return false
        } else {
          return true
        }
      })

      if (imgEl) {
        if (this.isSlideOuter) {
          imgSrc = imgEl.dataset.outerLazy
        } else if (this.isSlideInner) {
          imgSrc = imgEl.dataset.innerLazy
        } else {
          imgSrc = imgEl.dataset.lazy
        }

        imgEl.onload = () => {
          imgEl.classList.remove('img-lazy')
          imgEl.removeAttribute(this.isSlideOuter || this.isSlideInner ? `data-${this.nested}-lazy` : 'data-lazy')

          !this.isMove && this._lazyLoadImage()
        }

        imgEl.onerror = () => {
          !this.isMove && this._lazyLoadImage()
        }

        imgEl.src = imgSrc
      }

      if (this.images.length === 0) {
        this.$off('carousel:moved', this._lazyLoadImage)
      }
    },

    _bindEvents () {
      window.addEventListener('resize', this.handleWindowResize)
    },

    _unbindEvents () {
      window.removeEventListener('resize', this.handleWindowResize)

      this.$off('carousel:moved', this._lazyLoadImage)
    },

    handleWindowResize () {
      this._resize()
    },

    handleMouseWheel (e) {
      if (!this.wheel || this.isMove) {
        return
      }

      const { wheelDeltaY } = e
      const speed = 20

      if (wheelDeltaY < -speed) {
        this.next()
        this.$emit('wheelup')
      } else if (wheelDeltaY > speed) {
        this.prev()
        this.$emit('wheeldown')
      }
    },

    handleTrackMoved () {
      this.isMove = false
      this._slideUpdate()

      this.$emit('carousel:moved')
    },

    reload () {
      this.rootRect = null
      this.images = []

      this._unbindEvents()
      this._setup()
    },

    prev () {
      this.go(this.prevIndex)
    },

    next () {
      this.go(this.nextIndex)
    },

    go (index) {
      if (this.isMove || index === this.index) {
        return
      }

      this._toPosition(index)
    }
  }
}
</script>

<style lang="scss">
@import './VCarousel.scss';
</style>
