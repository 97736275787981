export default {
  methods: {
    convertKindToText (kind) {
      const kinds = ['movie', 'series', 'tvshow', 'live']

      if (kinds.indexOf(kind) !== -1) {
        return this.$t(`product.kind.${kind}`)
      } else {
        return kind
      }
    },

    convertRateToText (rate) {
      if (rate === 'RATE_13') {
        return '13+'
      } else if (rate === 'RATE_15') {
        return '15+'
      } else if (rate === 'RATE_18') {
        return '18+'
      } else if (rate === 'RATE_20') {
        return '20+'
      } else if (rate === 'RATE_GENERAL') {
        return this.$t('product.rate.general')
      } else {
        return rate
      }
    },

    convertAudioToText (audio) {
      if (audio === 'th') {
        return this.$t('product.audio.thaidub')
      } else if (audio === 'soundtrack') {
        return this.$t('product.audio.soundtrack')
      } else {
        return audio
      }
    }
  }
}
