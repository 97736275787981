import {
  forgotPasswordEmail,
  forgotPasswordSMS,
  verifyOTPCode,
  resendOTPCode,
  changePassword,
  setToken,
  removeToken
} from '../api'

const namespaced = true

const state = {
  method: 'email',
  username: null,
  token: null,
  refId: null
}

const mutations = {
  SET_METHOD (state, method) {
    state.method = method
  },

  SET_USERNAME (state, username) {
    state.username = username
  },

  SET_REFID (state, refId) {
    state.refId = refId
  },

  SET_TOKEN (state, token) {
    state.token = token
  }
}

const actions = {
  FORGOT_PASSWORD ({ commit }, { username, method }) {
    commit('SET_USERNAME', username)
    commit('SET_METHOD', method)

    const forgotPassword = (method === 'sms')
      ? forgotPasswordSMS(username)
      : forgotPasswordEmail(username)

    return forgotPassword.then((data) => commit('SET_REFID', data.ref))
  },

  VERIFY_OTP_CODE ({ state, commit }, { code }) {
    return verifyOTPCode(state.refId, code)
      .then((res) => res.data)
      .then((token) => commit('SET_TOKEN', token.access_token))
  },

  RESEND_OTP_CODE ({ state, commit }) {
    return resendOTPCode(state.refId)
      .then((data) => commit('SET_REFID', data.ref))
  },

  CHANGE_PASSWORD ({ state }, { password, confirmPassword }) {
    setToken(state.token)
    return changePassword(password, confirmPassword)
      .then((res) => {
        removeToken()
        return res.data
      })
  },

  CLEAR_STAGE_DATA ({ commit }) {
    commit('SET_USERNAME', null)
    commit('SET_TOKEN', null)
    commit('SET_REFID', null)
  }
}

const getters = {
  isActiveForgotPassword (state) {
    return !!state.refId
  },

  isActiveChangePassword (state, getters) {
    return getters.isActiveVerifyOTPCode && !!state.token
  },

  isActiveVerifyOTPCode (state, getters) {
    return getters.isActiveForgotPassword && state.method === 'sms'
  }
}

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
