import 'spatial-navigation-js'

import _forEach from 'lodash/forEach'
import _merge from 'lodash/merge'

const VueSpatialNavigation = {
  install (Vue) {
    const sn = Vue.prototype.$sn = window.SpatialNavigation

    sn.init()

    Vue.directive('sn', {
      inserted: function (el, binding, vnode) {
        const vm = vnode.context

        if (!vm) {
          console.log('Vue instance does not exists in VNode context')
          return
        }

        const { section, options } = binding.value || {}

        if (!section) {
          return
        }

        const $sn = vm.$sn || {}

        const newOptions = _merge({
          enterTo: 'last-focused'
        }, options)

        $sn.remove(section)
        $sn.add(section, newOptions)

        $sn.makeFocusable()

        vm._updateSnOptions(el, section, newOptions)
      },

      update: function (el, binding, vnode) {
        const vm = vnode.context

        if (!vm) {
          console.log('Vue instance does not exists in VNode context')
          return
        }

        const { section, options } = binding.value

        if (!section) {
          return
        }

        vm.$nextTick(() => vm._updateSnOptions(el, section, options))
      },

      unbind: function (el, binding, vnode) {
        var vm = vnode.context

        if (!vm) {
          console.log('Vue instance does not exists in VNode context')
          return
        }

        const $sn = vm.$sn || {}
        const { section } = binding.value

        $sn.remove(section)
      }
    })

    Vue.directive('sn-focusable', {
      inserted: function (el, binding, vnode) {
        const vm = vnode.context

        if (!vm) {
          console.log('Vue instance does not exists in VNode context')
          return
        }

        el.dataset.snFocusable = true
      }
    })

    Vue.mixin({
      beforeRouteLeave (to, from, next) {
        next((vm) => vm.$sn.pause())
      },

      methods: {
        _updateSnOptions (el, section, options) {
          if (!section) return

          const sectionEl = el || this.$el
          const selectors = sectionEl.querySelectorAll((options || {}).selector || '[data-sn-focusable]')

          this.$sn.set(section, { selector: [].slice.call(selectors) })
          this.$sn.makeFocusable()

          _forEach(selectors, (selector) => {
            selector.removeEventListener('sn:unfocused', this.handleSnUnFocused)
            selector.addEventListener('sn:unfocused', this.handleSnUnFocused)

            selector.removeEventListener('sn:focused', this.handleSnFocused)
            selector.addEventListener('sn:focused', this.handleSnFocused)

            var { snAutofocus } = selector.dataset
            var activeAutoFocus = !('snAutofocus' in selector.dataset) || (typeof snAutofocus === 'string' && snAutofocus !== 'disable')

            if (activeAutoFocus) {
              selector.removeEventListener('mouseover', this.handleSnAutoFocus)
              selector.addEventListener('mouseover', this.handleSnAutoFocus)
            }
          })
        },

        handleSnAutoFocus (e) {
          const targetEl = e.target

          if (targetEl !== document.activeElement) {
            this.$sn.focus(targetEl)
          }
        },

        handleSnUnFocused (e) {
          const { native } = e.detail
          const targetEl = e.target

          if (native) {
            this.$sn.focus(targetEl, true)
            return
          }

          const event = new CustomEvent("sn:deactive", { detail: e.detail })

          targetEl.classList.remove('is-focused')
          targetEl.dispatchEvent(event)
        },

        handleSnFocused (e) {
          const targetEl = e.target
          const event = new CustomEvent("sn:active", { detail: e.detail })

          targetEl.classList.add('is-focused')
          targetEl.dispatchEvent(event)
        }
      }
    })
  }
}

export default VueSpatialNavigation
