<template>
  <div class="v-player--duration-display">
    {{ duration | formatTime }}
  </div>
</template>

<script>
import { formatTime } from '@/utils/helpers'

export default {
  name: 'DurationDisplay',

  data () {
    return {
      duration: NaN
    }
  },

  filters: { formatTime },

  mounted () {
    this.$parent.$on([
      'player:durationchanged',
      'player:emptied'
    ], this.handleDurationDisplay)
  },

  beforeDestroy () {
    this.$parent.$off([
      'player:durationchanged',
      'player:emptied'
    ], this.handleDurationDisplay)
  },

  methods: {
    handleDurationDisplay () {
      this.duration = this.$parent.getDuration()
    }
  }
}
</script>
