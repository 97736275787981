<template>
  <div class="home-list offset-navbar">
    <v-background
      v-model="showBanner"
      :src="banner"
      size="cover"
      gradient
      @background:hide="showVideo = true"
    />

    <v-video-background
      v-model="showVideo"
      @video:playing="showBanner = false"
      @background:hide="showBanner = true"
      :playlist="videos"
      ref="videoBackground"
    />

    <product-preview :item="preview ? (preview.product || preview) : null">
      <div
        v-if="navs.length"
        class="home-list--navs"
      >
        <nav-list :items="navs" />
      </div>
    </product-preview>

    <div class="home-list--content">
      <product-list
        :lists="computeList"
        :infinite="activeInfinite"
        @infiniteload="handleInfiniteLoad"
        @itemselected="handleItemSelected"
        ref="productList"
      />
    </div>

    <v-spinner-loader v-model="showLoading" />
  </div>
</template>

<script>
import VSpinnerLoader from '@/components/VSpinnerLoader'
import VBackground from '@/components/VBackground'
import VVideoBackground from '@/components/VVideoBackground'
import ProductPreview from '../components/ProductPreview.vue'
import ProductList from '../components/ProductList.vue'
import NavList from '../components/NavList.vue'

import _slice from 'lodash/slice'

export default {
  name: 'HomeList',

  data () {
    return {
      activeInfinite: true,
      showLoading: true,
      showBanner: true,
      showVideo: false,
      banner: null,
      videos: [],
      navs: [],
      listPerPage: 5,
      listPage: 1,
      lists: [],
      preview: null
    }
  },

  computed: {
    computeList () {
      return _slice(this.lists, 0, this.listPage * this.listPerPage)
    }
  },

  components: {
    VSpinnerLoader,
    VBackground,
    VVideoBackground,
    ProductPreview,
    ProductList,
    NavList
  },

  beforeMount () {
    this._loadList()
  },

  beforeDestroy () {
    this._clearPreviewBanner()
    this._clearPreviewTrailer()
  },

  methods: {
    _clearPreviewBanner () {
      this.banner = null

      this._previewBannerTimer && clearTimeout(this._previewBannerTimer)
      this._previewBannerTimer = null
    },

    _loadPreviewBanner () {
      this._previewBannerTimer = setTimeout(() => {
        this.banner = this.preview ? this.preview.banner : null
      }, 2000) // 2 seconds.
    },

    _clearPreviewTrailer () {
      this.videos = []

      this._previewTrailerTimer && clearTimeout(this._previewTrailerTimer)
      this._previewTrailerTimer = null
    },

    _loadPreviewTrailer () {
      this._previewTrailerTimer = setTimeout(() => {
        this.$store.dispatch('FETCH_PRODUCT_TRAILER', { id: this.preview.id })
          .then((trailers) => this.videos = trailers)
      }, 4000) // 4 seconds.
    },

    handleInfiniteLoad () {
      this.$sn.pause()
      this.showLoading = true

      setTimeout(() => {
        this.listPage++

        if (this.computeList.length === this.lists.length) {
          this.activeInfinite = false
        }
      }, 200)
    },

    handleItemSelected (item, list) {
      const preview = item.product || item
      const hasProductItem = ['history', 'product', 'shortclips'].indexOf(list.type) !== -1

      if ((this.preview && this.preview.id === preview.id) || !hasProductItem) {
        if (list.type === 'shortclips') {
          this._clearPreviewTrailer()
        }
        return
      }

      this._clearPreviewBanner()
      this._clearPreviewTrailer()

      this.preview = preview
      this._loadPreviewBanner()

      if (['history', 'product'].indexOf(list.type) !== -1) {
        this._loadPreviewTrailer()
      }
    }
  }
}
</script>

<style lang="scss">
@import './HomeList.scss';
</style>
