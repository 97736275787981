<template>
  <v-fade-transition
    @fadein="$emit('background:show')"
    @fadeout="$emit('background:hide')"
  >
    <div
      v-show="show"
      :class="classes"
    >
      <v-fade-transition>
        <div
          v-if="background"
          :key="background"
          class="v-background--image"
          :style="styles"
        >
        </div>
      </v-fade-transition>

      <div class="v-background--overlay"></div>
    </div>
  </v-fade-transition>
</template>

<script>
import VFadeTransition from '@/transitions/VFadeTransition.vue'

export default {
  name: 'VBackground',

  props: {
    show: Boolean,
    src: String,
    bgColor: {
      type: String,
      default: '#060606'
    },
    size: {
      type: String,
      default: 'contain'
    },
    position: {
      type: String,
      default: 'center center'
    },
    gradient: Boolean,
    blur: Boolean
  },

  model: {
    prop: 'show',
    event: 'change'
  },

  data () {
    return {
      background: null
    }
  },

  computed: {
    classes () {
      return {
        'v-background': true,
        'v-background--gradient': this.gradient
      }
    },

    styles () {
      return this.background
        ? {
          'background-image': `url('${this.background}')`,
          'background-size': this.size,
          'background-position': this.position,
          'background-color': this.bgColor,
          'filter': this.blur ? 'blur(1rem)' : ''
        }
        : {}
    }
  },

  watch: {
    src: {
      handler (val) {
        if (val) {
          this.objImage = new Image()
          this.objImage.src = val
          this.objImage.onload = () => { this.background = val }
          this.objImage.onerror = () => { this.background = null }
        } else {
          this.background = null
        }
      },
      immediate: true
    }
  },

  components: {
    VFadeTransition
  }
}
</script>

<style lang="scss">
@import './VBackground.scss';
</style>
