var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'v-player': true,
    'v-player--ui': _vm.ui,
    'is-started': _vm.hasVideoStarted,
    'is-live': _vm.liveui || _vm.isVideoLive,
    'is-useractive': _vm.isUserActive,
    'is-dialogactive': _vm.isDialogActive,
    'is-waiting': _vm.isVideoWaiting,
    'is-seeking': _vm.isVideoSeeking,
    'is-playing': !_vm.isVideoPaused,
    'is-paused': _vm.isVideoPaused,
    'is-unload': _vm.isVideoUnload,
    'is-ended': _vm.isVideoEnded,
    'is-error': _vm.isVideoError
  },attrs:{"tabindex":"-1"},on:{"mousemove":_vm.handleMouseMove,"mouseup":_vm.handleMouseUp,"keydown":_vm.handleHotKeys}},[_c('div',{staticClass:"v-player--video"},[_c('video',{ref:"video",attrs:{"playsinline":"","preload":"auto"}})]),_c('v-fade-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showThumbnail),expression:"showThumbnail"}],staticClass:"v-player--thumbnail",style:({ backgroundImage: _vm.playback.thumbnail ? `url(${_vm.playback.thumbnail})` : false })})]),_c('div',{staticClass:"v-player--overlay"}),_c('v-spinner-loader',{staticClass:"v-player--spinner-loading",attrs:{"show":_vm.showLoading,"absolute":""}}),_c('div',{ref:"panel",staticClass:"v-player--skin-container"},[_c('div',{staticClass:"v-player--panel--top"},[_c('title-display')],1),_c('div',{ref:"text",staticClass:"v-player--panel--text",on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.handleTextPanelClick.apply(null, arguments)}}}),_c('div',{staticClass:"v-player--panel--bottom"},[_c('div',{staticClass:"v-player--controls--top"},[_c('play-toggle-button',{ref:"playToggle"}),_c('div',{staticClass:"v-player--progress-control"},[_c('time-display'),_c('seek-bar',{ref:"seekBar"}),_c('duration-display')],1)],1),_c('div',{staticClass:"v-player--controls--bottom"},[_c('back-button',{ref:"backButton"}),_c('video-settings-button',{ref:"videoSettingsDialogButton"}),_c('next-episode-button',{ref:"nextEpisodeButton"})],1)])]),_c('big-play-button',{ref:"bigPlayButton"}),_c('skip-button',{ref:"skipButton",model:{value:(_vm.showSkipButton),callback:function ($$v) {_vm.showSkipButton=$$v},expression:"showSkipButton"}}),_c('video-settings-dialog',{ref:"videoSettingsDialog",model:{value:(_vm.showVideoSettings),callback:function ($$v) {_vm.showVideoSettings=$$v},expression:"showVideoSettings"}}),_c('error-display',{ref:"errorDisplay"}),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }